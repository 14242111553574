import {Button} from 'reactstrap';
import React from 'react';
import swal from '@sweetalert/with-react';

const FileUploader2 = ({handleFile, name = 'Update Reference Photo'}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = event => {
    var _URL = window.URL || window.webkitURL;

    var file, img;
    if ((file = event.target.files[0])) {
        img = new Image();
        var objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
          if(this.width < 400 || this.height < 400){
            alert('Photo resolution is too small.\nPhotos should be at least 400 x 400 in pixels.');
            // swal({
            //   buttons: false,
            //   closeOnClickOutside: false,
            //   content:
            //     <div>
            //        Photo resolution is too small.<br/> 
            //        Photos should be at least 500 x 600 in pixels.
            //     </div>
            // });
          } else {
            const fileUploaded = file;
            handleFile(fileUploaded);
          }           
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    }    
  };
  return (
    <>
      <div style={{
        textAlign: 'center',
        font: '10px/15px Montserrat',
        fontWeight: '600',
        letterSpacing: '0',
        color: '#3668D9',
        cursor: 'pointer'
      }}
        onClick={handleClick}>
        {name}
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
}
export default FileUploader2;
