import { Form, Button, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import FileUploader from '../FileUploader';
import React, { useEffect, useState } from 'react';
import { createNotification, dateFormat, fetchAPIData, getDept } from '../../../helpers/Utils';
import { apiUrl } from '../../../constants/defaultValues';
import { Field, Formik } from 'formik';
import swal from '@sweetalert/with-react';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import { phone } from '../../../helpers/mapper';
import ImageCard from '../../../components/cards/ImageCard';
import FileUploader2 from '../FileUploader2';
import ExamplePhoto from '../../../assets/img/example-photo.png'

export const AccessManual = ({
                               deps,
                               onListRefreshed,
                               pEmail = '',
                               // pSex = 1,
                               pGroup = null,
                               pName = '',
                               pPhone = '',
                               pAccessCard = '',
                               pPhotoFile,
                               imagePath,
                               id,
                               isUpdate = false,
                               strangerId,
                               isInactive = false
                             }) => {
  let name = '';
  let surName = '';
  let split = pName.split(' ');
  if (split.length === 2) {
    name = split[0];
    surName = split[1];
  } else {
    name = pName;
  }
  if (pPhone.length === 10) {
    pPhone = '1' + pPhone;
  }

  // const [sex, setSex] = useState(pSex);
  const [email, setEmail] = useState(pEmail);
  const [group, setGroup] = useState(pGroup);
  const [photoFile, setPhotoFile] = useState(pPhotoFile);
  const [cardId, setCardId] = useState(pAccessCard);
  const [isSubmit, setIsSubmit] = useState(false);


  const addStaffConfirmed = (name, phone) => {
    insertCard()
      .then((payload) => {
        if (payload.success) {
          getSavedCardId()
            .then((payload) => {
              addPersonnel(payload.savedCardId, name, phone);
            });
        } else {
          //error
          createNotification('error', 'filled', payload.message, 'Error');
        }
      });
  };

  const addPersonnel = (savedCardId, name, phone) => {

    let params = new FormData();
    if (id)
      params.append('id', id);
    params.append('deptId', !group ? '' : group);
    params.append('name', name);
    // params.append('sex', sex);
    params.append('phone', phone);
    params.append('email', email);
    params.append('cardId', savedCardId);
    if (photoFile)
      params.append('files', photoFile);
    if (!isUpdate) {
      fetchAPIData(`${apiUrl}/cloudIntercom/v1/insertPerson`, params)
        .then((result) => {
          if (result.code === 1) {
            //error
            createNotification('error', 'filled', result.msg, 'Error');
          } else {


            if (strangerId) {
              let sparams = new FormData();
              sparams.append('strangerId', strangerId);
              sparams.append('staffId', result.id);
              fetchAPIData(`${apiUrl}/cloudIntercom/stranger/covert`, sparams)
                .then((rl) => {
                  createNotification('success', 'filled', 'Converted');
                  onListRefreshed(new Date());
                });
            } else {
              createNotification('success', 'filled', result.msg, 'Success');
              onListRefreshed(new Date());
            }
          }
        });
    } else {
      if (!isInactive) {
        fetchAPIData(`${apiUrl}/cloudIntercom/v1/updatePerson`, params)
          .then((result) => {
            if (result.code === 1) {
              //error
              createNotification('error', 'filled', result.msg, 'Error');
            } else {
              createNotification('success', 'filled', 'Update Success');
              onListRefreshed(new Date());
            }
          });
      } else {
        fetchAPIData(`${apiUrl}/cloudIntercom/v1/updateInactivePerson`, params)
          .then((result) => {
            if (result.code === 1) {
              //error
              createNotification('error', 'filled', result.msg, 'Error');
            } else {
              createNotification('success', 'filled', 'Update Success');
              onListRefreshed(new Date());
            }
          });
      }
    }
  };

  const getSavedCardId = () => new Promise(function(resolve, reject) {
    let payload = {
      savedCardId: ''
    };

    if (cardId.trim() === '') {
      payload.savedCardId = '';

      resolve(payload);
    } else {
      let params = new FormData();
      params.append('cardNum', cardId);

      fetchAPIData(`${apiUrl}/cloudIntercom/selectAllCard`, params)
        .then((result) => {
          payload.savedCardId = result.data.list[0].id;

          resolve(payload);
        });
    }
  });

  const insertCard = () => new Promise(function(resolve, reject) {
    let payload = {
      success: false,
      message: ''
    };

    if (cardId.trim() === '' || (pAccessCard === cardId)) {
      payload.success = true;

      resolve(payload);
    } else {
      let params = new FormData();
      params.append('cardNum', cardId);

      fetchAPIData(`${apiUrl}/cloudIntercom/insertCard`, params)
        .then((result) => {
          payload.success = result.code !== 1;
          payload.message = result.msg;

          resolve(payload);
        });
    }
  });

  const validateName = (value) => {
    let error;
    if (!value) {
      error = 'Please enter your name';
    }
    return error;
  };

  const validateSureName = (value) => {
    let error;
    if (!value) {
      error = 'Please enter your last name';
    }
    return error;
  };

  const validatePhone = (value) => {
    let error;
    if (!value) {
      error = 'Please enter your phone';
    } else if (value.length < 10) {
      error = 'Value must be longer than 2 characters';
    }
    return error;
  };

  return <>
    <Formik
      initialValues={{
        name: name,
        surName: surName,
        phone: pPhone
      }}
      // onSubmit={onSubmit}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form className="av-tooltip tooltip-label-right">
          <FormGroup>
            <div style={{
              font: '18px/22px Montserrat',
              fontWeight: 600,
              letterSpacing: 0,
              paddingBottom: 20,
              textAlign: 'center'
            }}>User Profile
            </div>
          </FormGroup>
          <FormGroup>
            <FormGroup>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '250px'}}>
                  <ImageCard imagePath={imagePath} size={100} sex={0} uploadedFile={photoFile}/><br/>
                  <InputGroup style={{ justifyContent: 'center'}}>
                    <Input
                      hidden={true}
                      readOnly
                      placeholder={'Staff Photo'}
                      value={photoFile ? photoFile.name : ''}
                    />
                    <InputGroupAddon addonType="append">
                      <FileUploader2
                        handleFile={(fl) => {
                          setPhotoFile(fl);
                        }}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div style={{width: '250px', textAlign: 'left', font: '10px/20px Montserrat'}}>
                  Reference photo should be similar to a passport photo.<br />
                      - Up-close very tight front facing shot<br />
                      - No accessories (no mask, glasses, hat, large earrings, scarf, etc)<br />
                      - Good lighting<br />
                      - Sharp and clear image<br />
                      - Avoid background distractions in the photo<br />
                      - Min 1,000x1,000 resolution<br />
                      - <a href={ExamplePhoto} style={{color:'rgb(54, 104, 217)'}} target="_blank">Example reference photo</a><br />                      
                </div>
              </div>
              <br/>
              <br/>
              <InputGroup style={{ justifyContent: 'center'}}>
              <div>
                  <div className="redit-user-input-label">First Name</div>
                  <Field
                    className="redit-user-input"
                    name="name"
                    defaultValue={name}
                    // validate={validateName}
                    placeholder={'First Name'}
                  />
                  {errors.name && touched.name && (
                    <div className="invalid-feedback d-block">
                      {errors.name}
                    </div>
                  )}
                </div>
                <Label/>
                <div>
                  <div className="redit-user-input-label">Last Name</div>
                  <Field
                    className="redit-user-input"
                    name="surName"
                    defaultValue={surName}
                    // validate={validateSureName}
                    placeholder={'Last Name'}
                  />
                  {errors.surName && touched.surName && (
                    <div className="invalid-feedback d-block">
                      {errors.surName}
                    </div>
                  )}
                </div>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ justifyContent: 'center'}}>
                <Label/>
                <div>
                  <div className="redit-user-input-label">Phone</div>
                  <Field
                    validate={validatePhone}
                    name={'phone'}
                    defaultValue={pPhone}
                    render={({ field }) => (<MaskedInput
                      {...field}
                      className="redit-user-input"
                      placeholder={'1-xxx-xxx-xxxx'}
                      onChange={handleChange}
                      mask={[
                        1,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                      ]}
                    />)}/>

                  {errors.phone && touched.phone && (
                    <div className="invalid-feedback d-block">
                      {errors.phone}
                    </div>
                  )}
                </div>
                <Label/>
                <div>
                  <div className="redit-user-input-label">Email</div>
                  <Field
                    className="redit-user-input"
                    placeholder={'Email'}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>

              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ justifyContent: 'center'}}>
              <div>
                  <div className="redit-user-input-label">Access Number &nbsp;&nbsp;<small style={{color:'#000', fontWeight:'600'}}>(Optional for RFID)</small></div>
                  <Field
                    className="redit-user-input"
                    placeholder={'Access Number'}
                    value={cardId}
                    onChange={(e) => setCardId(e.target.value)}
                  />
                </div>
                <div>
                  <div className="redit-user-input-label">Group</div>
                  <Input type="select"
                         style={{
                           height: '35px',
                           width: '180px'
                         }}
                         className="rnewbuttonblock redit-user-input"
                         onChange={(e) => setGroup(e.target.value)}>
                    <option value="" selected disabled hidden>Select Group</option>
                    {deps.map(dp => <option selected={dp.id === group} value={dp.id}>{dp.name}</option>)}
                  </Input>
                </div>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Button
                disabled={!photoFile && !isUpdate || !email || email.length < 4 || errors.surName || errors.name || values.phone && values.phone.replace(/-/g, '').replace(/-/g, '').replace(/_/g, '').length < 11 || !group}
                onClick={() => {

                  setIsSubmit(true);
                  addStaffConfirmed(values.name + ' ' + values.surName, values.phone.replace(/-/g, '').replace(/-/g, '').replace(/_/g, ''));
                  swal.stopLoading();
                  swal.close();
                }}
                className="r-button-reversed-gradient"

              >
                      <span className="spinner d-inline-block">
                        <span className="bounce1"/>
                        <span className="bounce2"/>
                        <span className="bounce3"/>
                      </span>
                <span className="label">
                        Save changes
                      </span>
              </Button>
            </FormGroup>
          </FormGroup>
        </Form>
      )}
    </Formik>
  </>;
};


export const TimedManual = ({
                              deps, onListRefreshed,
                              pEmail = '',
                              // pSex = 1,
                              pGroup = null,
                              pName = '',
                              pPhone = '',
                              pAccessCard = '',
                              pPhotoFile,
                              id,
                              isUpdate = false,
                              strangerId,
                              isInactive = false
                            }) => {

  let name = '';
  let surName = '';
  let split = pName.split(' ');
  if (split.length === 2) {
    name = split[0];
    surName = split[1];
  } else {
    name = pName;
  }
  if (pPhone.length === 10) {
    pPhone = '1' + pPhone;
  }

  // const [sex, setSex] = useState(pSex);
  const [email, setEmail] = useState(pEmail);

  const [group, setGroup] = useState(pGroup);
  const [photoFile, setPhotoFile] = useState(pPhotoFile);
  const [cardId, setCardId] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [expDate, setExpDate] = useState({ date: null, text: '' });

  const addVisitor = (name, phone) => {

    let params = new FormData();
    params.append('vgId', !group ? '' : group);
    params.append('name', name);
    // params.append('sex', sex);
    params.append('phone', phone);
    params.append('validTime', expDate.text);
    params.append('files', photoFile);

    fetchAPIData(`${apiUrl}/cloudIntercom/insertVisitor`, params)
      .then((result) => {
        if (result.code === 1) {
          //error
          createNotification('error', 'filled', result.msg, 'Error');
        } else {
          createNotification('success', 'filled', result.msg, 'Success');
          onListRefreshed();
        }
      });
  };

  const updateVisitor = (name, phone) => {
    let params = new FormData();
    params.append('vgId', !group ? '' : group);
    params.append('name', name);
    // params.append('sex', sex);
    params.append('phone', phone);
    params.append('validTime', expDate.text);
    params.append('files', photoFile);
    params.append('id', id);

    fetchAPIData(`${apiUrl}/cloudIntercom/updateVisitorById`, params)
      .then((result) => {
        if (result.code === 1) {
          //error
          createNotification('error', 'filled', result.msg, 'Error');
        } else {
          createNotification('success', 'filled', result.msg, 'Success');
          onListRefreshed();
        }
      });
  };

  const validateName = (value) => {
    let error;
    if (!value) {
      error = 'Please enter your name';
    }
    return error;
  };

  const validateSureName = (value) => {
    let error;
    if (!value) {
      error = 'Please enter your last name';
    }
    return error;
  };

  const validatePhone = (value) => {
    let error;
    if (!value) {
      error = 'Please enter your phone';
    } else if (value.length < 10) {
      error = 'Value must be longer than 2 characters';
    }
    return error;
  };

  return <>
    <Formik
      initialValues={{
        name: name,
        surName: surName,
        phone: pPhone
      }}
      // onSubmit={onSubmit}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form className="av-tooltip tooltip-label-right">
          <FormGroup>
            <Label>Please enter visitor information</Label>
          </FormGroup>
          <FormGroup>
            <FormGroup>
              <InputGroup>
                <Field
                  className="form-control"
                  name="name"
                  validate={validateName}
                  defaultValue={name}
                  placeholder={'First Name'}
                />
                {errors.name && touched.name && (
                  <div className="invalid-feedback d-block">
                    {errors.name}
                  </div>
                )}
                <Label/>
                <Field
                  className="form-control"
                  name="surName"
                  defaultValue={surName}
                  validate={validateSureName}
                  placeholder={'Last Name'}
                />
                {errors.surName && touched.surName && (
                  <div className="invalid-feedback d-block">
                    {errors.surName}
                  </div>
                )}
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Label/>

                <Field
                  validate={validatePhone}
                  name={'phone'}
                  defaultValue={pPhone}
                  render={({ field }) => (<MaskedInput
                    {...field}
                    className={'form-control'}
                    placeholder={'1-xxx-xxx-xxxx'}
                    onChange={handleChange}
                    mask={[
                      1,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                  />)}/>

                {errors.phone && touched.phone && (
                  <div className="invalid-feedback d-block">
                    {errors.phone}
                  </div>
                )}
                {/*<Input type="select"*/}
                {/*       onChange={(e) => setSex(e.target.value)}*/}
                {/*       placeholder={'Gender'}*/}
                {/*>*/}
                {/*  <option value={1}>Male</option>*/}
                {/*  <option value={0}>Female</option>*/}
                {/*</Input>*/}

              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <div style={{ 'width': '50%' }}>
                  <DatePicker

                    selected={expDate.date}
                    // ref={calendarRef}
                    onChange={(date) => {
                      setExpDate({ date: date, text: dateFormat(date) });
                    }}
                    placeholderText={'Pass Expiration'}
                  />
                </div>
                <Input type="select" onChange={(e) => setGroup(e.target.value)}>
                  <option value="" selected disabled hidden>Select Group</option>
                  {deps.map(dp => <option selected={dp.id === group} value={dp.id}>{dp.name}</option>)}
                </Input>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <Input
                  readOnly
                  placeholder={'Visitors Photo'}
                  value={photoFile ? photoFile.name : ''}
                />

                <InputGroupAddon addonType="append">
                  <FileUploader
                    handleFile={(fl) => {
                      setPhotoFile(fl);
                    }}
                  />


                  <Button color="secondary"
                          disabled={!photoFile || errors.surName || errors.name || values.phone.replace(/-/g, '').replace(/-/g, '').replace(/_/g, '').length < 11 || !group}
                          onClick={() => {

                            setIsSubmit(true);
                            {
                              isInactive ?
                                updateVisitor(values.name + ' ' + values.surName, values.phone.replace(/-/g, '').replace(/-/g, '').replace(/_/g, '')) :
                                addVisitor(values.name + ' ' + values.surName, values.phone.replace(/-/g, '').replace(/-/g, '').replace(/_/g, ''));
                            }
                            swal.stopLoading();
                            swal.close();
                          }}
                          className={`btn-shadow btn-multiple-state ${
                            isSubmit ? 'show-spinner' : ''
                          }`}

                  >
                      <span className="spinner d-inline-block">
                        <span className="bounce1"/>
                        <span className="bounce2"/>
                        <span className="bounce3"/>
                      </span>
                    <span className="label">
                        Submit
                      </span>
                  </Button>


                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </FormGroup>
        </Form>
      )}
    </Formik>
  </>;
};
