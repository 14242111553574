import React from 'react';
import { apiUrl } from '../../constants/defaultValues';

const ImageCard = ({ imagePath, sex, size = 50, id = 0, uploadedFile }) => {
  if (uploadedFile) {
    return (
      <div id={'photo-' + id} className="rdashbord-photo">
        <img
          src={URL.createObjectURL(uploadedFile)}
          alt=""
          height={size + 'px'}
          width={size + 'px'}
          className="rounded-circle tst"
        />
      </div>
    );
  } else if ((imagePath != null && imagePath !== '')) {
    return (
      <div id={'photo-' + id} className="rdashbord-photo">
        <img
          src={imagePath === '' ? `${apiUrl}/start/img/nopic.70c95dd5.png` : apiUrl + imagePath}
          alt=""
          height={size + 'px'}
          width={size + 'px'}
          className="rounded-circle tst"
        />
      </div>
    );
  } else {
    return (
      <div id={'photo-' + id} className="rdashbord-photo">
        <img src="https://live.rapidscreen.us/assets/icon/Male-icon.png"
             alt=""
             height={size + 'px'}
             width={size + 'px'}
        />
      </div>
    );
    // } else {
    //   return (
    //     <div className="xe iconsminds-female-2">
    //     </div>
    //   );
    // }
  }
};

export default React.memo(ImageCard);
