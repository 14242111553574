export const passTypeName = (passType) => {
  return passType === null ? 'All Scans' : passType === '2' ? 'Active' : passType === '1' ? 'Card Swipe' : passType === '9' ? 'Guest' : passType === '3' ? 'Paused' : passType === '4' ? 'Retired' : passType === '5' ? 'Banned' : passType === '10' ? 'Visitor' : passType === '0' ? 'Questionnaire Failures' : passType === '98' ? 'All Questionnaires' :'All';
};

export const phone = (phoneNumber) => {
  let phoneNumber1 = phoneNumber;
  if (phoneNumber1.length === 11) {
    return phoneNumber1.substring(0, 1) + '-' + phoneNumber1.substring(1, 4) + '-' + phoneNumber1.substring(4, 7) + '-' + phoneNumber1.substring(7, phoneNumber1.length);
  }
  else return phoneNumber1;
};
