import {Button} from 'reactstrap';
import React from 'react';
import UploadIcon from '../../assets/img/upload-icon.png'
import { Colxx } from '../../components/common/CustomBootstrap';
import loadImage from 'blueimp-load-image';

const FileUploader = ({handleFile, name = 'Upload Reference Photo'}) => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
    loadImage(
        fileUploaded,
        function (img, data) {
            console.log(data)
            if (data.imageHead) {
                img.toBlob(function (blob) {
                    loadImage.replaceHead(blob, data.imageHead, function (newBlob) {

                        handleFile(new File([blob], 'File.png', {
                            type: 'image/png',
                            lastModified: 1534584790000
                        }));//convert
                    })
                }, 'image/png')
            } else {
                handleFile(fileUploaded);
            }
        },
        { meta: true, canvas: true, maxWidth: 800 }
    )
    };

  return (
      <>
          <Colxx style={{
              textAlign: 'center',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              flexDirection:'column',
              width:'35%'
          }}
                 onClick={handleClick}>
              <img src={UploadIcon} style={{height:'80px'}} />
              <div style={{color:'#3668d9'}}>{name}</div>
          </Colxx>
          <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
          />
      </>
  );
}
export default FileUploader;
