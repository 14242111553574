import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey, apiUrl
} from '../constants/defaultValues';
import { NotificationManager } from '../components/common/react-notifications';
import swal from '@sweetalert/with-react';
import { AccessManual } from '../containers/registration/onboarding/manual';
import React from 'react';

export const mapOrder = (array, order, key) => {
  array.sort(function(a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentRadius -> error', error);
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentRadius -> error', error);
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage') &&
    localeOptions.filter(
      (x) => x.id === localStorage.getItem('currentLanguage')
    ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentLanguage -> error', error);
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentLanguage -> error', error);
  }
};


export const fetchAPIDataJSON = (fullUrl, body) => new Promise(function(resolve, reject) {
  const currentUser = getCurrentUser();
  fetch(fullUrl, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: new Headers({
      'Token': currentUser.token,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(body) // body data type must match "Content-Type" header
  })
    .then((result) => result.json())
    .then((result) => {
      if (result.code === 1001) {
        console.log('login expired');
        window.location.href = '../../user/login';
      } else {
        resolve(result);
      }
    });
});


export const fetchAPIData = (fullUrl, body) => new Promise(function(resolve, reject) {
  const currentUser = getCurrentUser();

  fetch(fullUrl, {
    headers: new Headers({
      'Token': currentUser.token
    }),
    method: 'POST',
    body: body
  })
    // .then((result) => result.json())
    .then((result) => {
      if (result.redirected) {
        console.log('login expired');
        window.location.href = '../../user/login';
      } else if (result.ok) {
        let body = result.json();
        if (body.code === 1001) {
          console.log('login expired');
          window.location.href = '../../user/login';
        } else {
          resolve(body);
        }
      } else {
        reject();
      }
    });
});

export const fetchAPIDataGET = (fullUrl) => new Promise(function(resolve, reject) {
  const currentUser = getCurrentUser();

  fetch(fullUrl, {
    headers: new Headers({
      'Token': currentUser.token
    }),
    method: 'GET'
  })
    .then((result) => result.json())
    .then((result) => {
      if (result.code === 1001) {
        console.log('login expired');
        window.location.href = '../../user/login';
      } else {
        resolve(result);
      }
    });
});

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem('gogo_current_user') != null ? JSON.parse(localStorage.getItem('gogo_current_user')) : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getTreeData = (result, obj, nameColumn) => {
  obj.name = result[nameColumn];
  obj.toggled = true;
  obj.id = result.id;
  if (typeof result.children !== 'undefined' && result.children !== null && result.children.length > 0) {
    obj.children = [];
    for (let i = 0; i < result.children.length; i++) {
      let tempObj = {
        name: result.children[i][nameColumn],
        children: result.children[i].children,
        id: result.children[i].id
      };
      let children = getTreeData(result.children[i], tempObj, nameColumn);
      obj.children.push(children);
    }
  }

  return obj;
};

export const getNotTreeData = (array, obj, nameColumn) => {
  for (let i = 0; i < array.length; i++) {
    let tempObj = {
      name: array[i][nameColumn],
      children: array[i].children,
      id: array[i].id
    };
    let children = getTreeData(array[i], tempObj, nameColumn);
    obj.push(children);
  }

  return obj;
};


export const createNotification = (type, className, msg, title) => {
  const cName = className || '';
  switch (type) {
    case 'primary':
      NotificationManager.primary(
        'This is a notification!',
        'Primary Notification',
        3000,
        null,
        null,
        cName
      );
      break;
    case 'secondary':
      NotificationManager.secondary(
        'This is a notification!',
        'Secondary Notification',
        3000,
        null,
        null,
        cName
      );
      break;
    case 'info':
      NotificationManager.info('Info message', '', 3000, null, null, cName);
      break;
    case 'success':
      NotificationManager.success(
        msg,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'warning':
      NotificationManager.warning(
        'Warning message',
        'Close after 3000ms',
        3000,
        null,
        null,
        cName
      );
      break;
    case 'error':
      NotificationManager.error(
        msg,
        title,
        5000,
        () => {
          alert('callback');
        },
        null,
        cName
      );
      break;
    default:
      NotificationManager.info('Info message');
      break;
  }
};

export const onModalToggled = (modalOpen, setFn) => {
  setFn(!modalOpen);
};

export const onSelectionNecessaryToggled = (modalOpen, setFn, arrayToCheck, errorMsg) => {
  let isNull = typeof arrayToCheck === 'undefined' || arrayToCheck === null || arrayToCheck.length <= 0;

  if (!isNull) {
    onModalToggled(modalOpen, setFn);
  } else {
    createNotification('error', 'filled', errorMsg, 'Error');
  }
};

export const onListRefreshed = (setListRefresh) => {
  setListRefresh(new Date());
};


export const getDept = (apiSettings, fun) => {
  fetchAPIData(apiSettings.dataUrl, null)
    .then((rls) => {
      if (rls.data.length > 1) {
        let data = getNotTreeData(rls.data, [], apiSettings.nameColumn);
        let deps = [];
        {
          for (let i = 0; i < data.length; i++) {
            deps.push({ name: data[i].name.replace(new RegExp('\\(.*\\)'), ''), id: data[i].id });
            expand(data, deps);
          }
          fun(deps);
        }
      } else {
        let data = getTreeData(apiSettings.isArray ? rls.data[0] : rls.data, {}, apiSettings.nameColumn);
        let deps = [];
        {
          deps.push({ name: data.name.replace(new RegExp('\\(.*\\)'), ''), id: data.id });
          expand(data, deps, apiSettings.dataUrl);

          fun(deps);
        }
      }
    });
};

export function editPersonPopup(personId, photoUrl, cardNum, deps, setListRefresh, isUpdate = true) {
  let params = new FormData();
  params.append('id', personId);

  fetchAPIData(`${apiUrl}/cloudIntercom/getPerson`, params)
    .then((result) => {
      let data = typeof result.data === 'undefined' || result.data === null ? [] : result.data;
      if (!isUpdate) {
        {
          var xhr = new XMLHttpRequest();
          let photo = photoUrl;
          let split = photo.split('.');
          xhr.open('GET', apiUrl + photo, true);
          xhr.responseType = 'blob';
          var ctType;
          xhr.onreadystatechange = function() {
            if (this.readyState === this.HEADERS_RECEIVED) {
              ctType = xhr.getResponseHeader('Content-Type');
            }
          };
          xhr.onload = function(e) {
            console.log(this.response);
            const reader = new FileReader();
            reader.onload = function(event) {
              var res = event.target.result;
              console.log(res);
            };
            var blob = this.response;
            reader.readAsDataURL(blob);
            const file = new File([blob], 'File.' + split[1], {
              type: ctType,
              lastModified: 1534584790000
            });
            console.log(file);
            swal({
                buttons: false,
                content: <AccessManual
                  strangerId={data.id}
                  pPhotoFile={file}
                  deps={deps}
                  onListRefreshed={setListRefresh}
                />
              }
            );
          };
          xhr.send();
        }
      } else {
        swal({
          buttons: false,
          content: <AccessManual
            id={data.id}
            pAccessCard={cardNum}
            pPhone={data.phone}
            pEmail={data.email}
            pName={data.name}
            pSex={data.sex}
            imagePath={photoUrl}
            pGroup={data.deptId}
            isUpdate={isUpdate}
            onListRefreshed={setListRefresh}
            deps={deps}
            isInactive={false}
          />
        });
      }
    });
}

function expand(data, deps, url) {

  if (data.children) {
    data.children.map((w) => {
      if (w.children || url.endsWith('selectDimissDeptEmpTree') || url.endsWith('selectAllVisitorGroup'))
        deps.push({ name: w.name.replace(new RegExp('\\(.*\\)'), ''), id: w.id });
      expand(w, deps, url);
    });
  }
}

export function timeFormat(date) {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
}

export function dateFormat(date) {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2);
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const phoneMask = [
  1,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
